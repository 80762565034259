<template>
    <div></div>
</template>

<script> 
import { mapState, mapGetters } from 'vuex';

import Helper from '../assets/js/helper';

export default {
    name: "SupportChat",
    props: {
        isBrl: Boolean
    },
    computed: {
        ...mapState([
            'user'
        ]),
        ...mapGetters([
            'isPlan',
            'getSlug'
        ])  
    },
    created(){
        // let script = document.createElement('script');

        // if(this.isBrl){
        //     let env = ['dev', 'test'].includes(Helper.getEnvs().env) ? 'io' : 'com';

        //     script.src = `https://ba-support.builderall.${env}/js/checkout/support.js?locale=${this.user.lang}&${this.isPlan ? 'plan' : 'fee'}=${this.getSlug}`;
        //     document.head.appendChild(script);
        // }else{
        //     //script.src = 'https://office.builderall.com/scripts/ba-support/chat.js?v=1.0&env=production&lang=us';
        //     //document.head.appendChild(script);
        // }
    }
    
}
</script>